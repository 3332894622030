import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type {
  Breakpoints,
  ComponentsOptions,
  MixinsOverrides,
  Palette,
  Typography,
} from '../../../../base/src';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { createComponents as createBaseComponents } from '../../../../base/src';

export const createComponents = (
  breakpoints: Breakpoints,
  mixins: MixinsOverrides,
  palette: Omit<Palette, 'mode'>,
  typography: Typography,
): ComponentsOptions => {
  const baseComponents = createBaseComponents(breakpoints, mixins, palette, typography);

  const components: ComponentsOptions = {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.other.tooltip,
          color: palette.grey[50],
          maxWidth: 300,
        },
      },
    },
    Button: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: '16px 30px',
        },
        primary: {
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.dark,
          },
          '@media (hover: none)': {
            '&:hover': {
              backgroundColor: palette.primary.main,
            },
          },
          boxShadow: `0px 10px 40px -10px ${palette.primary.main}4D`,
        },
        icon: mixins.setColorSvg(palette.primary.main),
        iconTextBase: {
          fontFamily: typography.fontFamily,
          color: palette.text.link,
          '&:hover': {
            ...mixins.setColorSvg(palette.text.hover),
            color: palette.text.hover,
            textDecoration: 'underline',
          },
        },
        outlinePrimary: {
          borderColor: palette.primary.main,
          color: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.background,
            color: palette.primary.dark,
            borderColor: palette.primary.dark,
          },
        },
        text: {
          fontFamily: typography.fontFamily,
        },
      },
    },
    Card: {
      styleOverrides: {
        horizontalDivider: {
          display: 'none',
        },
      },
    },
    Checkbox: {
      styleOverrides: {
        icon: {
          width: 20,
          height: 20,
          borderColor: palette.grey['400'],
        },
        iconChecked: {
          borderWidth: 2,
          backgroundColor: palette.other.body,
          color: palette.primary.main,
        },
      },
    },
    Select: {
      styleOverrides: {
        option: {
          '&:hover': {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    Toggle: {
      styleOverrides: {
        toggleButton: {
          fontFamily: typography.fontFamily,
        },
      },
    },
  };

  return merge({}, baseComponents, components);
};
