import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './DocumentSection.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        link: css({
          textDecoration: 'underline',
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
