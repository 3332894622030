import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './FullScreenModal.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          cardHeader: css({
            backgroundColor: theme.palette.grey[200],
            borderBottom: `1px solid ${theme.palette.other.divider}`,
          }),
          headerTitle: css({ color: theme.palette.text.primary }),
          icon: css(theme.mixins.setColorSvg(theme.palette.grey[900])),
          button: css({ '&:hover': theme.mixins.setColorSvg(theme.palette.grey[900]) }),
        },
      },
    }),
  );
};
