import { merge } from '@ecp/utils/common';

import type { Breakpoints, TypographyOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { createTypography as createTypographyBase } from '../../../../base/src';

export const createTypography = (breakpoints: Breakpoints): TypographyOverrides => {
  const baseTypography = createTypographyBase(breakpoints);

  const typographyOverrides: TypographyOverrides = {
    body1: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1Bold: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1BoldItalics: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1BoldUnderline: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body1Italics: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body1ItalicsUnderline: {
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body1Underline: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Bold: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2BoldItalics: {
      fontSize: 12,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Italics: {
      fontSize: 12,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body2Underline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body3: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Bold: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3BoldItalics: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Italics: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
    },
    body3Underline: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    body4: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    body4Bold: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
    },
    body4Underline: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
    },
    button2: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
    },
    button2Medium: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
    },
    button2MediumUnderline: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button2Small: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
    },
    button2SmallUnderline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    button2Underline: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '18px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
    buttonMedium: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'left',
    },
    buttonSmall: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '14px',
      textAlign: 'left',
    },
    fontFamily: 'Lato',
    fontFamilyFallback: 'Roboto',
    fontFamilySecondary: 'Roboto',
    fontFamilySecondaryFallback: 'Roboto',
    h1: {
      fontFamily: 'Merriweather',
      fontSize: 28,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '28px',
      },
    },
    h2: {
      fontSize: 22,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {},
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontWeight: 400,
      },
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontWeight: 400,
      },
    },
    header1: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'left',
    },
    htmlFontSize: 14,
    subtitle: {
      fontSize: 22,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      [breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '24px',
      },
    },
    subtitleUnderline: {
      fontSize: 22,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '32px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  } as const;

  const response = merge({}, baseTypography, typographyOverrides);

  return {
    ...response,
    fontFamily: [response.fontFamily, response.fontFamilyFallback].filter((x) => x).join(','),
    fontFamilySecondary: [response.fontFamilySecondary, response.fontFamilySecondaryFallback]
      .filter((x) => x)
      .join(','),
  };
};
