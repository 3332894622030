import type { GeneratedBrandData } from '../brand.types';

export const brand: GeneratedBrandData = {
  home: {
    partnerCompanyName: 'Lemonade',
    mainOperationHours: [
      'Monday - Friday 8:00am - 11:00pm ET',
      'Saturday 9:30am - 8:00pm ET',
      'Sunday: CLOSED',
    ],
    mainCustServPhoneNum: '833-854-1200',
    mainBrandNameShort: 'Lemonade',
    brandingType: 'White Label',
    salesOperationHours: ['Monday - Friday 9:00am - 7:00pm ET'],
    salesPhoneNum: '833-854-1200',
    salesChatHours: [],
    serviceChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    servicingUrl: 'https://lemonade.homesite.com',
    claimPhoneNum: '833-854-1200',
    inboundServiceEmail: 'customerservice@homesite.com',
    serviceAddress: {
      addressLine1: 'Homesite Insurance',
      addressLine2: 'PO Box 5300',
      city: 'Binghamton',
      state: 'NY',
      zip: '13902',
      type: 'General Service Mailing Address',
      zipPlus4: '9953',
    },
    paymentsAddress: {
      addressLine1: 'Homesite Insurance',
      addressLine2: 'PO Box 5300',
      city: 'Binghamton',
      state: 'NY',
      zip: '13902',
      type: 'Payments Mailing Address',
      zipPlus4: '9953',
    },
    uwCompany: 'Homesite',
    uwCompanyCopyright: 'Homesite Group Incorporated',
  },
};
