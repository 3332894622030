import { LegalCopy as LegalCopyWrapper } from '@ecp/features/servicing/shared/components';
import type { ProductLine } from '@ecp/features/servicing/shared/types';
import { getBrandDataByType } from '@ecp/features/servicing/shared/util';

interface LegalCopyProps {
  productLine: ProductLine;
}

export const LegalCopy: React.FC<LegalCopyProps> = (props) => {
  const { productLine } = props;
  const brandData = getBrandDataByType(productLine);

  return (
    <LegalCopyWrapper>
      I authorize Homesite Group, Incorporated and its affiliates to initiate the single electronic
      payment listed on this page from my checking, savings, or debit/credit card account. I
      understand that payment will be deducted on or after today's date once I select "Pay Now". If
      I have questions regarding this payment, I understand that I can contact you at{' '}
      {brandData?.mainCustServPhoneNum}.{' '}
    </LegalCopyWrapper>
  );
};
