import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './CoveragesSection.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        coveragesSection: css({
          borderTop: `4px solid ${theme.palette.grey[600]}`,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
