import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#ececec',
    disabledText: '#818181',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
    white15: '#ffffff26',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#c30440',
    darkText: '#4a4a4a',
    light: '#fee0e0',
    main: '#de2b49',
  },
  grey: {
    '50': '#ffffff',
    '100': '#fbfbfb',
    '200': '#f7f7f7',
    '300': '#f2f2f2',
    '400': '#ececec',
    '500': '#d2d2d2',
    '600': '#b7b7b7',
    '700': '#818181',
    '800': '#666666',
    '900': '#4a4a4a',
  },
  icon: {
    dark: '#4a4a4a',
    default: '#ffffff',
    hover: '#ff0083',
    stroke: '#818181',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#225aaf',
    darkText: '#4a4a4a',
    light: '#ebf5ff',
    main: '#2a76e4',
  },
  other: {
    backdrop: '#22222266',
    background: '#ff0083',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#ececec',
    caution: '#ff9900',
    cookieBanner: '#ffffff',
    divider: '#d2d2d2',
    snackbar: '#4c4c4c',
    tooltip: '#4a4a4a',
  },
  partner: {
    footerBackground: '#4a4a4a',
    footerTextLink: '#ffffff',
    footerTextPrimary: '#ffffff',
    footerTextSecondary: '#f2f2f2',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#ffffff',
    border: '#ff0083',
    contrastText: '#ffffff',
    dark: '#dc0073',
    darkText: '#b40060',
    light: '#f2f2f2',
    main: '#ff0083',
  },
  secondary: {
    background: '#ffffff',
    border: '#ff0083',
    contrastText: '#ffffff',
    dark: '#dc0073',
    darkText: '#b40060',
    light: '#f2f2f2',
    main: '#ff0083',
  },
  shadow: {
    primary: '#00000033',
  },
  showcase: {
    color: '#44444426',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#24b8a3',
    darkText: '#4a4a4a',
    light: '#dbffec',
    main: '#27d8b5',
  },
  text: {
    disabled: '#818181',
    hover: '#dc0073',
    link: '#ff0083',
    primary: '#4a4a4a',
    secondary: '#666666',
    tertiary: '#757575',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#ff9900',
    darkText: '#4a4a4a',
    light: '#fff3c7',
    main: '#ffb01c',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);
