import { LogoLemonadeAbbrv } from '@ecp/themes/base';

import { SiteWideLinks as SiteWideLinksBase } from './SiteWideLinks.js';
import { useStyles } from './SiteWideLinks.styles';

export const SiteWideLinks: React.FC = () => {
  const { classes } = useStyles();

  return <SiteWideLinksBase logo={<LogoLemonadeAbbrv className={classes.logo} />} />;
};
