import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './ServicingHeaderBody.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          active: css({
            '&:hover': {
              ...theme.typography.body1,
              color: theme.palette.primary.main,
              borderBottomColor: `${theme.palette.primary.main} !important`,
            },
          }),
          button: css({
            color: theme.palette.text.primary,
            '& .MuiButton-startIcon > svg ': { display: 'none' },
            '&:hover': { ...theme.typography.body1Bold },
          }),
        },
      },
    }),
  );
};
