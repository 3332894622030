import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './SiteWideLinks.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          logo: css({
            height: 40,
            width: 40,
          }),
          links: css({
            color: theme.palette.primary.contrastText,
            '&:hover': {
              ...theme.typography.body3Bold,
              color: theme.palette.primary.contrastText,
            },
          }),
          header: css({ color: theme.palette.primary.contrastText }),
        },
      },
    }),
  );
};
