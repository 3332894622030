import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './Alert.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          root: css({
            '& a, & button': {
              color: theme.palette.text.primary,
              '&:hover': { color: theme.palette.text.primary, fontWeight: 700 },
            },
          }),
        },
      },
    }),
  );
};
