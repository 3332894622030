import { LogoLemonadeAbbrv } from '@ecp/themes/base';

import { LogoSpinner as LogoSpinnerBase } from './LogoSpinner.js';
import { useStyles } from './LogoSpinner.styles.lemonade';

export const LogoSpinner: React.FC = () => {
  const { classes } = useStyles({ loaderPositionStyle: 'fixed' });

  return <LogoSpinnerBase logo={<LogoLemonadeAbbrv className={classes.logo} />} />;
};
