import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './CheckIcon.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          circle: css({
            backgroundColor: theme.palette.grey[50],
          }),
          icon: css({
            ...theme.mixins.setColorSvg(theme.palette.primary.main),
          }),
        },
      },
    }),
  );
};
